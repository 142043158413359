import { gql } from '@apollo/client';
import { fragments } from 'graphql/queries';

export const accommodationBookingFoodTypeFragments = {
  accommodationBookingFoodTypes: gql`
    fragment AccommodationBookingFoodTypes on AccommodationBookingFoodTypes {
      items {
        ...AccommodationBookingFoodType
      }
      paging {
        ...Paging
      }
    }
  `,
  accommodationBookingFoodType: gql`
    fragment AccommodationBookingFoodType on AccommodationBookingFoodType {
      id
      name
      nameEn
      translations {
        ...Translation
      }

      createdDate
      lastModifiedDate
    }
  `,
};

export const getAccommodationBookingFoodType = gql`
  query getAccommodationBookingFoodType($id: Int!) {
    getAccommodationBookingFoodType(id: $id) {
      ...AccommodationBookingFoodType
    }
  }
  ${accommodationBookingFoodTypeFragments.accommodationBookingFoodType}
  ${fragments.translation}
`;

export const getAccommodationBookingFoodTypes = gql`
  query ($paging: SearchPaging!, $sorting: Sorting, $searchCriteria: AccommodationBookingFoodTypeSearchCriteria) {
    getAccommodationBookingFoodTypes(paging: $paging, sorting: $sorting, searchCriteria: $searchCriteria) {
      ...AccommodationBookingFoodTypes
    }
  }
  ${accommodationBookingFoodTypeFragments.accommodationBookingFoodType}
  ${accommodationBookingFoodTypeFragments.accommodationBookingFoodTypes}
  ${fragments.translation}
  ${fragments.paging}
`;
