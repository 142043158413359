import { Maybe, Scalars, UserSettings } from './types';

export type SearchPaging = {
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};

export type Sorting = {
  orderBy: Array<OrderBy>;
};

export enum OrderBy {
  EmailAsc = 'EmailAsc',
  EmailDesc = 'EmailDesc',
  FirstNameAsc = 'FirstNameAsc',
  FirstNameDesc = 'FirstNameDesc',
  LastNameAsc = 'LastNameAsc',
  LastNameDesc = 'LastNameDesc',
  LanguageAsc = 'LanguageAsc',
  LanguageDesc = 'LanguageDesc',
  IdAsc = 'IdAsc',
  IdDesc = 'IdDesc',
  NameAsc = 'NameAsc',
  NameDesc = 'NameDesc',
  DescriptionAsc = 'DescriptionAsc',
  DescriptionDesc = 'DescriptionDesc',
  DateAsc = 'DateAsc',
  DateDesc = 'DateDesc',
  DateCreatedAsc = 'DateCreatedAsc',
  DateCreatedDesc = 'DateCreatedDesc',
  DateUpdatedAsc = 'DateUpdatedAsc',
  DateUpdatedDesc = 'DateUpdatedDesc',
  DateTimeAsc = 'DateTimeAsc',
  DateTimeDesc = 'DateTimeDesc',
  UsedDateAsc = 'UsedDateAsc',
  UsedDateDesc = 'UsedDateDesc',
  CreatedDateAsc = 'CreatedDateAsc',
  CreatedDateDesc = 'CreatedDateDesc',
  LastModifiedDateAsc = 'LastModifiedDateAsc',
  LastModifiedDateDesc = 'LastModifiedDateDesc',
  TypeAsc = 'TypeAsc',
  TypeDesc = 'TypeDesc',
  AmountAsc = 'AmountAsc',
  AmountDesc = 'AmountDesc',
  CodeAsc = 'CodeAsc',
  CodeDesc = 'CodeDesc',
  FullNameAsc = 'FullNameAsc',
  FullNameDesc = 'FullNameDesc',
  LiveAsc = 'LiveAsc',
  LiveDesc = 'LiveDesc',
  YearAsc = 'YearAsc',
  YearDesc = 'YearDesc',
  RoomTypeAsc = 'RoomTypeAsc',
  RoomTypeDesc = 'RoomTypeDesc',
  FoodTypeAsc = 'FoodTypeAsc',
  FoodTypeDesc = 'FoodTypeDesc',
  NameEnAsc = 'NameEnAsc',
  NameEnDesc = 'NameEnDesc',
  OrderNumberAsc = 'OrderNumberAsc',
  OrderNumberDesc = 'OrderNumberDesc',
  ExecutionDateAsc = 'ExecutionDateAsc',
  ExecutionDateDesc = 'ExecutionDateDesc',
}

export type RegisterUser = {
  email: Scalars['String'];
  password: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobileNumber?: Maybe<Scalars['String']>;
  settings: UserSettings;
  invitationCode: Scalars['String'];
};

export type UserSearchCriteria = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type UserNotificationSettingInput = {
  type: Scalars['String'];
  email: Scalars['Boolean'];
  app: Scalars['Boolean'];
};
