import { useLocation } from 'react-router-dom';
import { LanguageCode, SecurityRole } from 'types/types.d';

/**
 * Public routes
 */
export const HOME = '/';
export const LOGIN = 'login';
export const LOGIN_TWO_FA = 'login-two-fa';
export const REGISTER = 'register';
export const REQUEST_CODE = 'request-code';
export const EMAIL_CONFIRMATION = 'confirm-email';
export const PROFILE_DELETION = 'delete-profile';
export const PASSWORD_RECOVER = 'recover-password';
export const PASSWORD_RESET = 'reset-password';

/**
 * USER private routes
 */
export const DASHBOARD = 'dashboard';

export const ENTITY_ID = ':id';
export const ENTITY_ADD = 'add';

export const SALES = 'sales';
export const SALES_REQUESTS = `${SALES}/requests`;
export const SALES_REQUESTS_ADD = `${SALES_REQUESTS}/${ENTITY_ADD}`;
export const SALES_REQUESTS_DETAILS = `${SALES_REQUESTS}/${ENTITY_ID}`;
export const SALES_BOOKINGS = `${SALES}/bookings`;
export const SALES_BOOKINGS_ADD = `${SALES_BOOKINGS}/${ENTITY_ADD}`;
export const SALES_BOOKINGS_DETAILS = `${SALES_BOOKINGS}/${ENTITY_ID}`;
export const SALES_CANCELLATIONS = `${SALES}/cancellations`;
export const SALES_CANCELLATIONS_DETAILS = `${SALES_CANCELLATIONS}/${ENTITY_ID}`;
export const SALES_ALL_CONTACTS = `${SALES}/all-contacts`;
export const SALES_CONTACTS = `${SALES}/contacts`;
export const SALES_CONTACTS_ADD = `${SALES_CONTACTS}/${ENTITY_ADD}`;
export const SALES_CONTACTS_DETAILS = `${SALES_CONTACTS}/${ENTITY_ID}`;

export const ACADEMIC = `academic`;
export const ACADEMIC_COURSE_GROUPS = `${ACADEMIC}/course-groups`;
export const ACADEMIC_COURSE_GROUP_TYPES = `${ACADEMIC}/course-group-types`;
export const ACADEMIC_TEACHERS = `${ACADEMIC}/teachers`;
export const ACADEMIC_CLASSROOMS = `${ACADEMIC}/classrooms`;
export const ACADEMIC_ANALYTICS = `${ACADEMIC}/analytics`;

export const ACCOMMODATION = `accommodation`;
export const ACCOMMODATION_CALENDAR = `${ACCOMMODATION}/calendar`;
export const ACCOMMODATION_CALENDAR_ONSITE = `${ACCOMMODATION_CALENDAR}/onsite`;
export const ACCOMMODATION_CALENDAR_OFFSITE = `${ACCOMMODATION_CALENDAR}/offsite`;
export const ACCOMMODATION_CALENDAR_HOST_FAMILIES = `${ACCOMMODATION_CALENDAR}/families`;
export const ACCOMMODATION_APARTMENTS = `${ACCOMMODATION}/apartments`;
export const ACCOMMODATION_AVAILABILITY = `${ACCOMMODATION}/availability`;
export const ACCOMMODATION_ANALYTICS = `${ACCOMMODATION}/analytics`;

export const MARKETING = `marketing`;
export const MARKETING_CONVERSIONS = `${MARKETING}/conversions`;
export const MARKETING_FOLLOWUPS = `${MARKETING}/followups`;
export const MARKETING_FOLLOWUP_TEMPLATES = `${MARKETING}/followup-templates`;
export const MARKETING_AUTOMATIC_FOLLOWUP_EVENTS = `${MARKETING}/automatic-followup-events`;
export const MARKETING_RECEIPT_TEMPLATES = `${MARKETING}/receipt-templates`;
export const MARKETING_ANALYTICS = `${MARKETING}/analytics`;

export const FINANCE = `finance`;
export const FINANCE_PAYMENTS = `${FINANCE}/payments`;
export const FINANCE_INVOICES = `${FINANCE}/invoices`;
export const FINANCE_STRIPE = `${FINANCE}/stripe`;
export const FINANCE_BRAINTREE = `${FINANCE}/braintree`;
export const FINANCE_PAYPAL = `${FINANCE}/paypal`;

export const ENTERTAINMENT = `entertainment`;
export const ENTERTAINMENT_EVENTS = `${ENTERTAINMENT}/events`;
export const ENTERTAINMENT_ANALYTICS = `${ENTERTAINMENT}/analytics`;

export const CONFIG = `config`;
export const CONFIG_AGENCIES = `${CONFIG}/agencies`;
export const CONFIG_COURSES = `${CONFIG}/courses`;
export const CONFIG_ACCOMMODATIONS = `${CONFIG}/accommodations`;
export const CONFIG_ACCOMMODATION_PROVIDERS = `${CONFIG}/accommodation-providers`;
export const CONFIG_SCHOOL = `${CONFIG}/school`;
export const CONFIG_OFFICE_MANAGERS = `${CONFIG}/office-managers`;
export const CONFIG_USERS = `${CONFIG}/users`;

export const PROFILE = 'profile';

export const allPrivateRoutes = [
  DASHBOARD,
  SALES,
  SALES_REQUESTS,
  SALES_REQUESTS_DETAILS,
  SALES_REQUESTS_ADD,
  SALES_BOOKINGS,
  SALES_BOOKINGS_DETAILS,
  SALES_BOOKINGS_ADD,
  SALES_CANCELLATIONS,
  SALES_CANCELLATIONS_DETAILS,
  SALES_CONTACTS,
  SALES_CONTACTS_ADD,
  SALES_CONTACTS_DETAILS,

  ACADEMIC,
  ACADEMIC_COURSE_GROUPS,
  ACADEMIC_COURSE_GROUP_TYPES,
  ACADEMIC_TEACHERS,
  ACADEMIC_ANALYTICS,
  ACADEMIC_CLASSROOMS,

  ACCOMMODATION,
  ACCOMMODATION_CALENDAR,
  ACCOMMODATION_CALENDAR_ONSITE,
  ACCOMMODATION_CALENDAR_OFFSITE,
  ACCOMMODATION_CALENDAR_HOST_FAMILIES,
  ACCOMMODATION_APARTMENTS,
  ACCOMMODATION_AVAILABILITY,
  ACCOMMODATION_ANALYTICS,

  ENTERTAINMENT,
  ENTERTAINMENT_EVENTS,
  ENTERTAINMENT_ANALYTICS,

  MARKETING,
  MARKETING_CONVERSIONS,
  MARKETING_FOLLOWUPS,
  MARKETING_FOLLOWUP_TEMPLATES,
  MARKETING_AUTOMATIC_FOLLOWUP_EVENTS,
  MARKETING_RECEIPT_TEMPLATES,
  MARKETING_ANALYTICS,

  FINANCE,
  FINANCE_PAYMENTS,
  FINANCE_INVOICES,
  FINANCE_STRIPE,
  FINANCE_PAYPAL,
  FINANCE_BRAINTREE,

  CONFIG,
  CONFIG_AGENCIES,
  CONFIG_COURSES,
  CONFIG_ACCOMMODATIONS,
  CONFIG_ACCOMMODATION_PROVIDERS,
  CONFIG_SCHOOL,
  CONFIG_OFFICE_MANAGERS,
  CONFIG_USERS,

  PROFILE,
];

export const URL_QUERY_PARAM_KEY = 'key';
export const URL_QUERY_PARAM_CODE = 'code';
export const URL_QUERY_PARAM_INVITATION = 'invitation';
export const URL_QUERY_PARAM_ACTION = 'action';
export const URL_QUERY_PARAM_ACTION_LOGIN = 'login';
export const URL_QUERY_PARAM_ACTION_REG = 'reg';
export const URL_QUERY_PARAM_EXPIRED_SESSION = 'expired';
export const URL_QUERY_PARAM_TOKEN = 'token';
export const URL_QUERY_PARAM_ERROR = 'error';
export const URL_QUERY_PARAM_LANGUAGE = 'lang';

export const COOKIE_REFRESH_TOKEN = 'refresh_token';

export const useURLSearchParams = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

export const getURLSearchParams = (): URLSearchParams => {
  return new URLSearchParams(window.location.search);
};

/**
 * Teacher: Groups related routes
 *
 * @return true if the route is allowed for the passed role incl. excluded permissions list
 */
export const isRouteAllowed = (route: string, role: SecurityRole): boolean => {
  switch (role) {
    case SecurityRole.TEACHER:
      return [ACADEMIC_COURSE_GROUPS, PROFILE].includes(route);

    default:
      return true;
  }
};

export const getTermsAndConditionsLink = (languageCode: string): string => {
  switch (languageCode) {
    case LanguageCode.EN:
      return 'https://sample.com/terms-and-conditions';
    default:
      return 'https://sample.com/terms-and-conditions';
  }
};

export const getPrivacyPolicyLink = (languageCode: string): string => {
  switch (languageCode) {
    case LanguageCode.EN:
      return 'https://sample.com/privacy-policy';
    default:
      return 'https://sample.com/privacy-policy';
  }
};

export const getPageTitleI18nKeyBasedOnRoute = (routeName: string): string => {
  // cut the first "/" off
  const name = routeName.substr(1);

  switch (name) {
    // Dashboard
    case DASHBOARD:
      return 'private.sidebar.dashboard';

    // Admin
    case SALES_REQUESTS:
      return 'private.sidebar.sales_requests';
    case SALES_BOOKINGS:
      return 'private.sidebar.sales_bookings';
    case SALES_CANCELLATIONS:
      return 'private.sidebar.sales_cancellations';
    case SALES_ALL_CONTACTS:
      return 'private.sidebar.sales_all_contacts';
    case SALES_CONTACTS:
      return 'private.sidebar.sales_contacts';

    // Academic
    case ACADEMIC_COURSE_GROUPS:
      return 'private.sidebar.academic_course_groups';
    case ACADEMIC_COURSE_GROUP_TYPES:
      return 'private.sidebar.academic_course_group_types';
    case ACADEMIC_TEACHERS:
      return 'private.sidebar.academic_teachers';
    case ACADEMIC_CLASSROOMS:
      return 'private.sidebar.academic_classrooms';
    case ACADEMIC_ANALYTICS:
      return 'private.sidebar.academic_analytics';

    // Accommodation
    case ACCOMMODATION_CALENDAR:
      return 'private.sidebar.accommodation_calendar';

    // Marketing
    case MARKETING_CONVERSIONS:
      return 'private.sidebar.marketing_conversions';
    case MARKETING_FOLLOWUPS:
      return 'private.sidebar.marketing_followups';
    case MARKETING_AUTOMATIC_FOLLOWUP_EVENTS:
      return 'private.sidebar.marketing_automatic_followup_events';
    case MARKETING_FOLLOWUP_TEMPLATES:
      return 'private.sidebar.marketing_followup_templates';
    case MARKETING_RECEIPT_TEMPLATES:
      return 'private.sidebar.marketing_receipt_templates';

    // Finance
    case FINANCE_PAYMENTS:
      return 'private.sidebar.finance_payments';
    case FINANCE_INVOICES:
      return 'private.sidebar.finance_invoices';
    case FINANCE_STRIPE:
      return 'private.sidebar.finance_stripe';
    case FINANCE_BRAINTREE:
      return 'private.sidebar.finance_braintree';
    case FINANCE_PAYPAL:
      return 'private.sidebar.finance_paypal';

    // Config
    case CONFIG_AGENCIES:
      return 'private.sidebar.config_agencies';
    case CONFIG_COURSES:
      return 'private.sidebar.config_courses';
    case CONFIG_ACCOMMODATIONS:
      return 'private.sidebar.config_accommodations';
    case CONFIG_ACCOMMODATION_PROVIDERS:
      return 'private.sidebar.config_accommodation_providers';
    case CONFIG_SCHOOL:
      return 'private.sidebar.config_school';
    case CONFIG_OFFICE_MANAGERS:
      return 'private.sidebar.config_office_managers';
    case CONFIG_USERS:
      return 'private.sidebar.config_users';

    default:
      return '';
  }
};
